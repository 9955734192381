/* eslint no-console:0 */
const Rails = require('@rails/ujs');

Rails.start();

require('../stylesheets/ecs/application.sass');

require('../javascript/shared/components/accordion');
require('../javascript/shared/components/burger_menu');
require('../javascript/shared/components/dropdown');
require('../javascript/shared/components/dropdown_advanced');
require('../javascript/shared/components/modal');
require('../javascript/shared/klaro-config');

require('../javascript/ecs/date_input_select');
require('../javascript/ecs/loader');
require('../javascript/ecs/login');
require('../javascript/ecs/password_field');
require('../javascript/ecs/file-downloader');

require('../javascript/ecgcc/site_select');
